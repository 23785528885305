<template>
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1129_3670)">
      <path
        d="M24.889 4.66602H3.11122C2.69866 4.66602 2.303 4.8299 2.01128 5.12163C1.71955 5.41335 1.55566 5.80901 1.55566 6.22157V21.7771C1.55566 22.1897 1.71955 22.5854 2.01128 22.8771C2.303 23.1688 2.69866 23.3327 3.11122 23.3327H14.7779L15.2212 22.7882L15.9446 21.9016L15.8746 21.7771H3.11122V6.22157H24.889V12.8793C25.48 13.2617 26.0053 13.7371 26.4446 14.2871V6.22157C26.4446 5.80901 26.2807 5.41335 25.9889 5.12163C25.6972 4.8299 25.3016 4.66602 24.889 4.66602Z"
        fill="#14005C"
      />
      <path
        d="M5.44434 9.33398H18.6666V10.5784H5.44434V9.33398Z"
        fill="#14005C"
      />
      <path
        d="M5.44434 12.4453H13.9999V13.6898H5.44434V12.4453Z"
        fill="#14005C"
      />
      <path
        d="M5.44434 17.8887H13.2221V19.1331H5.44434V17.8887Z"
        fill="#14005C"
      />
      <path
        d="M21.358 13.4005C20.3627 13.3926 19.3882 13.6852 18.5618 14.2399C17.7354 14.7946 17.0955 15.5858 16.7259 16.5099C16.3562 17.4341 16.2739 18.4482 16.4898 19.4199C16.7057 20.3915 17.2096 21.2754 17.9358 21.9561L16.4269 23.7994L17.1269 26.6072L19.9736 23.1383C20.8747 23.4048 21.8336 23.4048 22.7347 23.1383L25.5814 26.6072L26.2814 23.7994L24.7725 21.9561C25.4986 21.2754 26.0026 20.3915 26.2185 19.4199C26.4343 18.4482 26.352 17.4341 25.9824 16.5099C25.6127 15.5858 24.9729 14.7946 24.1465 14.2399C23.3201 13.6852 22.3455 13.3926 21.3502 13.4005H21.358ZM21.358 21.7072C20.6971 21.698 20.0536 21.4936 19.5085 21.1197C18.9634 20.7458 18.541 20.2191 18.2944 19.6058C18.0479 18.9925 17.9881 18.32 18.1227 17.6729C18.2572 17.0257 18.5801 16.4328 19.0507 15.9686C19.5214 15.5044 20.1187 15.1898 20.7677 15.0642C21.4166 14.9386 22.0882 15.0076 22.6981 15.2627C23.3079 15.5177 23.8287 15.9473 24.195 16.4975C24.5614 17.0478 24.7568 17.694 24.7569 18.355C24.7487 19.2496 24.3865 20.1045 23.7495 20.7328C23.1125 21.361 22.2527 21.7114 21.358 21.7072Z"
        fill="#14005C"
      />
    </g>
    <defs>
      <clipPath id="clip0_1129_3670">
        <rect width="28" height="28" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script setup lang="ts"></script>
