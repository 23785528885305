<template>
  <div v-if="items" id="select">
    <v-select
      v-model="defaultValueModel"
      clearable
      :density="density"
      :disabled="disabled"
      :items="items as T[]"
      :no-data-text="$t('no_data_available')"
      :variant="outlined ? 'outlined' : 'plain'"
      :item-title="itemTitle"
      :item-value="itemValue"
      :rules="rules"
      :hide-details="hideDetails"
      :label="$t(label)"
      :multiple="multiple"
      :placeholder="placeholder"
      :return-object="returnObject"
      @update:modelValue="changeItem"
    >
      <template v-if="$slots.selection" #selection="props">
        <slot name="selection" :props="props" />
      </template>
    </v-select>
  </div>
</template>

<script setup lang="ts" generic="T">
import { watch, ref, Ref } from "vue";

type ValidatorCallback<T> = (value: T) => boolean | string;

type Items = T extends unknown[] ? T : T[];

const emits = defineEmits(["change-item"]);

const props = defineProps<{
  defaultValue?: T | null;
  label: string;
  items: Items;
  itemTitle?: string;
  itemValue?: string;
  rules?: ValidatorCallback<string>[];
  outlined?: boolean;
  hideDetails?: boolean;
  type?: string;
  disabled?: boolean;
  translate?: boolean;
  density?: "default" | "comfortable" | "compact";
  multiple?: boolean;
  placeholder?: string;
  returnObject?: boolean;
}>();

const defaultValueModel = ref<T | null>(
  props.defaultValue ?? null,
) as Ref<T | null>;

watch(
  () => props.defaultValue,
  newVal => {
    if (newVal !== undefined) {
      defaultValueModel.value = newVal;
    }
  },
);

const changeItem = (value: T | null): void => {
  emits("change-item", { label: props.type, value });
};
</script>
