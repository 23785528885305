<template>
  <TheDialog
    :dialog="dialog"
    max-width="600px"
    :title="t('assign_license')"
    :submit-text="t('assign')"
    :cancel-text="t('cancel')"
    @onSubmit="handleSubmit"
    @onCancel="handleClose"
    @onClose="handleClose"
  >
    <template #text>
      {{
        t("assign_license_description", {
          licenseKey: license?.licenseKey,
        })
      }}
      <div class="mt-5">
        <TheAutocomplete
          v-model="trucker"
          :rules="rules"
          :items="truckerItems"
          :label="t('search')"
          :is-loading="truckersLoading"
          prepend-inner-icon="mdi-magnify"
          @on-search="handleSearch"
          @load-more="handleLoadMore"
        />
      </div>

      <div>
        <TheAlert v-if="errorMessage" type="error" :text="errorMessage" />
      </div>
    </template>
  </TheDialog>
</template>
<script setup lang="ts">
import TheDialog from "@/components/dialogs/TheDialog.vue";
import { UseDialogReturn } from "@/composables/useDialog";
import { useI18n } from "vue-i18n";
import { License } from "@/models/license-management";
import { computed, ref } from "vue";
import TheAutocomplete from "@/components/TheAutocomplete.vue";
import { TruckerForForwarderTO } from "@/services/client/generated";
import { ruleRequired } from "@/utils/vuetify-rules";
import TheAlert from "@/components/TheAlert.vue";

interface Props {
  dialog: UseDialogReturn<License>;
  truckers?: TruckerForForwarderTO[] | null;
  truckersLoading?: boolean;
}

const emit = defineEmits(["onSearch", "onLoadMoreTruckers", "onSubmit"]);
const props = defineProps<Props>();
const { t } = useI18n();
const rules = [ruleRequired()];

const trucker = ref<TruckerForForwarderTO | undefined>();

const truckerItems = computed(() => {
  return props.truckers?.map(trucker => {
    return {
      title: `${trucker.firstName} ${trucker.lastName}`,
      value: trucker,
    };
  });
});
const license = computed(() => props.dialog.param.value);

const errorMessage = computed<string | undefined>(() => {
  const error = props.dialog.error;

  if (error.value) return t(error.value?.code);
  return undefined;
});

function handleSearch(value: string) {
  emit("onSearch", value);
}

function handleLoadMore(done: unknown) {
  emit("onLoadMoreTruckers", done);
}

function handleSubmit() {
  emit("onSubmit", { trucker: trucker.value, license: license.value });
}

function handleClose() {
  trucker.value = undefined;
}
</script>
