<template>
  <div>
    <TheMainHeader class="text-left pl-10 pt-10" :text="$t('statusRequest')" />

    <div>
      <v-container fluid class="pt-10 px-10 pb-6">
        <VirtualTable
          :items="getStatusResponseQueue"
          :headers="headers"
          :header-section-name="selectedTerminal?.name"
          :row-props="getRowClass"
        >
          <template #header>
            <GetStatusStateIndicator
              :is-resubmit-batch-button-disabled="!fetchCompleted"
              :length-of-all-requests="getStatusRequestQueue.length"
              :progress-state="progressState"
              :terminal="selectedTerminal"
              :fetch-completed="fetchCompleted"
              @submit-csv="getBatchStatusQueue"
              @download-csv="downloadCSV"
            />
          </template>

          <template #item.type="{ index }">
            {{ missing($t(getStatusRequestQueue[index].type)) }}
          </template>

          <template #item.referenceNumber="{ index }">
            {{ missing($t(getStatusRequestQueue[index].referenceNumber)) }}
          </template>

          <template #item.containerNumber="{ index }">
            {{
              missing($t(getStatusRequestQueue[index].containerNumber ?? ""))
            }}
          </template>

          <template #item.containerType="{ value }">
            {{ missing($t(value.value?.container?.containerType ?? "")) }}
          </template>

          <template #item.netWeight="{ value }">
            {{ missing($t(value.value?.container?.netWeight ?? "")) }}
          </template>

          <template #item.tollData="{ value }">
            {{ hasData(value.value?.container?.listTollData) }}
          </template>

          <template #item.dangerousGoods="{ value }">
            {{ hasData(value.value?.container?.listDangerousGoodsData) }}
          </template>

          <template #item.slotStart="{ value }">
            {{ parseTimeslot(value.value?.timeSlots?.[0]) }}
          </template>

          <template #item.slotEnd="{ value }">
            {{
              parseTimeslot(
                value.value?.timeSlots?.[value.value.timeSlots.length - 1],
              )
            }}
          </template>
        </VirtualTable>
      </v-container>
    </div>

    <div
      v-for="(containerError, index) in containerErrorMessages"
      :key="index"
      class="px-10"
    >
      <v-alert color="red" variant="outlined" class="mb-4" type="warning">
        {{ containerError }}
      </v-alert>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import TheMainHeader from "@/components/TheMainHeader.vue";
import {
  GetStatusRequest,
  GetStatusRequestResponseTO,
  GetStatusResponse,
  Terminal,
  TimeSlot,
} from "@/services/client/generated";
import QueueProgressIndicator from "@/views/forwarder-operator/get-status-batch/models/queue-progress-indicator";
import GetStatusStateIndicator from "@/views/forwarder-operator/get-status-batch/GetStatusStateIndicator.vue";
import { getErrorByTypeOrDefault } from "@/utils/error-utils";
import { Result } from "@/internal-models/result";
import { validateContainerNumberFormat } from "@/validators/container-validators";
import { useI18n } from "vue-i18n";
import { getViewModel } from "./get-status-batch-logic";
import VirtualTable from "@/components/virtual-table/VirtualTable.vue";
import { getViewModel as forwarderBookingViewModel } from "../bookings-overview/forwarder-bookings-overview-logic";

const { locale, t } = useI18n();
const router = useRouter();
const route = useRoute();
const viewModel = getViewModel();
const forwarderViewModel = forwarderBookingViewModel();

const selectedFileName = ref("");
const selectedTerminalIdInput = ref("");
const selectedGetStatusRequestStringified = ref("");
const selectedTerminal = ref<Terminal | undefined>(undefined);
const terminalId = ref<number | null>(null);

const fetchCompleted = ref(false);
const progressState = ref<QueueProgressIndicator>({
  currentIndex: 0,
  successfulItems: 0,
  failedItems: 0,
});

const getStatusRequestQueue = ref<GetStatusRequest[]>([]);
const getStatusResponseQueue = ref<Result<GetStatusResponse>[]>([]);

const headers = computed(
  () =>
    [
      {
        title: t("type"),
        value: "type",
        align: "center",
        width: "2em",
      },
      {
        title: t("referenceNumber"),
        value: "referenceNumber",
        align: "center",
      },
      {
        title: t("containerNumber"),
        value: "containerNumber",
        align: "center",
      },
      { title: t("containerType"), value: "containerType", align: "center" },
      {
        title: t("netWeight"),
        value: "netWeight",
        align: "center",
      },
      {
        title: t("dangerousGoods"),
        value: "dangerousGoods",
        align: "center",
      },
      {
        title: t("tollData"),
        value: "tollData",
        align: "center",
      },
      {
        title: t("slotStart"),
        value: "slotStart",
        align: "center",
      },
      {
        title: t("slotEnd"),
        value: "slotEnd",
        align: "center",
      },
      {
        title: "",
        value: "select",
        align: "center",
      },
    ] as const,
);

const missing = (value: string | undefined | null): string =>
  !value ? t("missing") : value;

const hasData = (data?: Array<unknown>): string => {
  if (!data) {
    return t("missing");
  }
  return data && data.length > 0 ? t("true") : t("false");
};

const parseTimeslot = (timeslot: TimeSlot | undefined): string => {
  if (!timeslot) return t("missing");
  return forwarderViewModel.parseTimeslot(timeslot, locale.value);
};

const initializeComponent = async (): Promise<void> => {
  fetchCompleted.value = false;
  getStatusRequestQueue.value = JSON.parse(
    selectedGetStatusRequestStringified.value,
  ) as GetStatusRequest[];
  terminalId.value = parseInt(selectedTerminalIdInput.value);

  progressState.value = { currentIndex: 0, successfulItems: 0, failedItems: 0 };
  getStatusResponseQueue.value = [];

  if (terminalId.value) {
    selectedTerminal.value = await viewModel.getTerminalById(terminalId.value);
  }

  await fetchContainers();
};

const fetchContainers = async (): Promise<void> => {
  const promises = getStatusRequestQueue.value.map(async (containerData, _) => {
    const result = await getContainerData(containerData);
    getStatusResponseQueue.value.push(result);
    if (result.isOk) {
      progressState.value.successfulItems++;
    } else {
      progressState.value.failedItems++;
    }
    progressState.value.currentIndex++;
  });

  await Promise.all(promises);
  fetchCompleted.value = true;
};

const getContainerData = async (
  containerData: GetStatusRequest,
): Promise<Result<GetStatusResponse>> => {
  if (!validateContainerNumberFormat(containerData.containerNumber as string)) {
    return {
      isOk: false,
      error: { errorMessage: "container_number_invalid_format" },
    };
  }

  if (terminalId.value === null) {
    return { isOk: false, error: { errorMessage: "terminal_id_is_null" } };
  }

  try {
    const statusResponse = await viewModel.getStatus(
      terminalId.value,
      containerData,
    );
    if (!statusResponse.timeSlots || statusResponse.timeSlots.length === 0) {
      return {
        isOk: false,
        error: { errorMessage: "booking_has_no_timeslots" },
      };
    }
    return { value: statusResponse, isOk: true };
  } catch (e) {
    const errorMessage = t(getErrorByTypeOrDefault(e));
    return { isOk: false, error: { errorMessage: errorMessage as string } };
  }
};

const downloadCSV = async (): Promise<void> => {
  if (!fetchCompleted.value) return;

  const getStatusBatched: GetStatusRequestResponseTO[] =
    getStatusRequestQueue.value.map((request, i) => {
      const response = getStatusResponseQueue.value[i];
      const getStatusGroupedResponse: GetStatusRequestResponseTO = {
        getStatusRequest: request,
      };
      if (response.isOk) {
        getStatusGroupedResponse.getStatusResponse = response.value;
      } else {
        getStatusGroupedResponse.errorCode = response.error?.errorMessage;
      }
      return getStatusGroupedResponse;
    });

  if (terminalId.value) {
    await viewModel.downloadCSV(
      terminalId.value,
      getStatusBatched,
      selectedFileName.value,
    );
  }
};

const getRowClass = (item: { item: Result<GetStatusResponse> }) => {
  const rowClass = {
    "bg-red": !item.item.isOk,
  };
  return { class: rowClass };
};

const getBatchStatusQueue = async (payload: { file: File }): Promise<void> => {
  try {
    const parsingResponse = await viewModel.getStatusFileCallParameters(
      payload.file,
    );
    if (parsingResponse.failed) {
      await viewModel.showGetStatusError(parsingResponse.error);
      return;
    }

    selectedGetStatusRequestStringified.value = JSON.stringify(
      parsingResponse.items,
    );
    selectedFileName.value = payload.file.name;
    initializeComponent();
  } catch (e) {
    const errorMessage = t(getErrorByTypeOrDefault(e));
    await viewModel.showGetStatusError(t(errorMessage) as string);
  }
};

const containerErrorMessages = computed(() => {
  return getStatusResponseQueue.value
    .map((response, index) => {
      if (!response.isOk) {
        const request = getStatusRequestQueue.value[index];
        const containerNumber = request.containerNumber;
        const referenceNumber = request.referenceNumber;
        const type = request.type;

        const appropriateLine = `containerError${
          containerNumber ? "With" : "Without"
        }ContainerNumber`;

        return t(appropriateLine, {
          containerNumber,
          referenceNumber,
          error: t(response.error?.errorMessage ?? ""),
          type: t(type ?? ""),
        });
      }
      return null;
    })
    .filter(error => error !== null);
});
onMounted(async () => {
  if (!route.params.terminalIdInput) {
    router.back();
  }
  selectedFileName.value = route.params.fileName as string;
  selectedTerminalIdInput.value = route.params.terminalIdInput as string;
  selectedGetStatusRequestStringified.value = route.params
    .getStatusRequestStringified as string;

  await initializeComponent();
});
</script>

<style scoped lang="scss"></style>
