import { onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";

export function usePurchaseStatus(successCallback?: () => void) {
  const route = useRoute();
  const router = useRouter();

  function removePurchaseSuccessFromQuery() {
    const query = { ...route.query };
    delete query.purchaseSuccess;
    router.replace({ query });
  }

  onMounted(() => {
    if (route.query.purchaseSuccess === "true") {
      successCallback?.();
      removePurchaseSuccessFromQuery();
    }
  });
}
