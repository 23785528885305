<template>
  <nav class="breadcrumb">
    <div
      v-for="(item, index) in items"
      :key="index"
      class="breadcrumb-item"
      :class="{ active: isLast(index) }"
    >
      <TheText
        v-if="!isLast(index)"
        color="primary"
        class="breadcrumb-link"
        @click="navigateTo(item.to)"
      >
        {{ item.text }}
      </TheText>
      <TheText color="natural-800" v-else>
        {{ item.text }}
      </TheText>
    </div>
  </nav>
</template>

<script setup lang="ts">
import { useRouter } from "vue-router";
import { BreadcrumbItem } from "@/models/breadcrumbs";
import TheText from "@/components/TheText.vue";

const props = defineProps<{
  items: BreadcrumbItem[];
}>();

const router = useRouter();

const isLast = (index: number) => {
  return index === props.items.length - 1;
};

const navigateTo = (path: string) => {
  router.push(path);
};
</script>

<style lang="scss" scoped>
.breadcrumb {
  display: flex;
  align-items: center;
  padding: 0.5rem 0;

  .breadcrumb-item {
    display: flex;
    align-items: center;

    & + .breadcrumb-item::before {
      content: "/";
      padding: 0 0.5rem;
    }

    .breadcrumb-link {
      cursor: pointer;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
