<template>
  <div class="pa-10">
    <TheBreadcrumbs :items="breadcrumbs" />
    <TheMainHeader class="text-left pt-10" :text="$t('license_management')" />
    <v-card class="pa-4 mt-10">
      <LicenseManagementTable
        :page="page"
        :licenses="licenses ?? []"
        @on-search="handleSearch"
        @on-filter="handleFilterClick"
        @onLoadMore="handleLoadMore"
        @on-claim-license="handleClaimLicenseClick"
        @on-purchase-license="handlePurchaseClick"
        @on-license-assign="handleAssignClick"
        @on-revoke="handleRevokeClick"
      />
      <LicenseManagementFiltersDialog
        :dialog="filterDialog"
        v-model="filter"
        @on-apply="handleFilterApplyClick"
      />
      <LicenseManagementClaimLicenseDialog
        :claimed-licenses="claimedLicenses"
        :dialog="claimLicenseDialog"
        @onSubmit="handleClaimLicenseSubmit"
      />
      <LicenseManagementPurchaseDialog
        :dialog="purchaseDialog"
        @on-submit="handlePurchaseSubmitClick"
      />
      <LicenseManagementAssignDialog
        :dialog="assignDialog"
        :truckers="truckersForForwarder"
        :truckers-loading="truckersLoading"
        @on-search="handleSearchTruckers"
        @on-load-more-truckers="handleLoadMoreTruckers"
        @onSubmit="handleAssignLicense"
      />
      <LicenseManagementRevokeDialog
        :dialog="revokeDialog"
        @onSubmit="handleRevokeSubmit"
      />
    </v-card>
  </div>
</template>

<script setup lang="ts">
import TheMainHeader from "@/components/TheMainHeader.vue";
import LicenseManagementTable from "@/components/license-management/LicenseManagementTable.vue";
import { useLicenseManagementLogic } from "@/views/forwarder-operator/license-management/license-management-logic";
import LicenseManagementPurchaseDialog from "@/components/license-management/LicenseManagementPurchaseDialog.vue";
import LicenseManagementAssignDialog from "@/components/license-management/LicenseManagementAssignDialog.vue";
import LicenseManagementFiltersDialog from "@/components/license-management/LicenseManagementFiltersDialog.vue";
import LicenseManagementClaimLicenseDialog from "@/components/license-management/LicenseManagementClaimLicenseDialog.vue";
import TheBreadcrumbs from "@/components/TheBreadcrumbs.vue";
import LicenseManagementRevokeDialog from "@/components/license-management/LicenseManagementRevokeDialog.vue";

const {
  page,
  licenses,
  truckersForForwarder,
  claimedLicenses,
  filterDialog,
  assignDialog,
  claimLicenseDialog,
  purchaseDialog,
  revokeDialog,
  filter,
  truckersLoading,
  breadcrumbs,
  handleFilterApplyClick,
  handleFilterClick,
  handleClaimLicenseClick,
  handlePurchaseClick,
  handleAssignClick,
  handleRevokeClick,
  handleRevokeSubmit,
  handleSearch,
  handleSearchTruckers,
  handleLoadMoreTruckers,
  handleLoadMore,
  handlePurchaseSubmitClick,
  handleClaimLicenseSubmit,
  handleAssignLicense,
} = useLicenseManagementLogic();
</script>
