<template>
  <v-autocomplete
    :items="items"
    :item-title="itemTitle"
    :model-value="model"
    :label="label"
    @update:search="fetchData"
    @update:modelValue="handleModelValue"
  >
    <template #append-item>
      <v-infinite-scroll
        @load="onScroll"
        :mode="'intersect'"
        style="overflow-y: unset"
      />
    </template>

    <template v-if="$slots.item" #item="{ item: { value } }">
      <slot name="item" :value="value" />
    </template>
  </v-autocomplete>
</template>

<script setup lang="ts" generic="T">
import { useDebounce } from "@/composables/useDebounce";

interface Props {
  items?: { title: string; value: T }[];
  itemTitle?: string;
  label?: string;
  isLoading?: boolean;
}

const { debounce } = useDebounce(300);

const model = defineModel<T>();
const props = defineProps<Props>();
const emit = defineEmits(["onSearch", "loadMore"]);

const onScroll = ({
  done,
}: {
  done: (status: "loading" | "error" | "empty" | "ok") => void;
}) => {
  if (!props.items || props.items.length === 0) {
    done("empty");
  }
  console.log("Load More");
  emit("loadMore", done);
};

function fetchData(value: string) {
  debounce(() => {
    emit("onSearch", value);
  });
}

function handleModelValue(value: T) {
  model.value = value;
}
</script>
