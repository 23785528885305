import { dateService, DateService } from "@/services/business/date-service";
import { forwarderTruckersClient } from "@/services/client/configs/services";
import {
  ForwarderTruckersApiApi,
  TruckerForForwarderTO,
  UpdateTruckerForForwarderTO,
} from "@/services/client/generated";
import { useAuthStore } from "@/store/useAuthStore";
import { useForwarderStore } from "@/store/useForwarderStore";

export class ForwarderTruckerManagementLogic {
  private static readonly TRUCKERS_PER_PAGE: number = 50;

  constructor(
    private authStore: ReturnType<typeof useAuthStore>,
    private forwarderStore: ReturnType<typeof useForwarderStore>,
    private dateService: DateService,
    private forwarderTruckerManagementService: ForwarderTruckersApiApi,
  ) {}

  async getTruckersForForwarder(
    page: number,
  ): Promise<TruckerForForwarderTO[]> {
    const userId: string = this.authStore.authUser?.userId ?? "";
    const forwarderId: number = this.forwarderStore.getForwarder()?.id ?? 0;

    if (!userId || !forwarderId) {
      throw new Error("User ID or Forwarder ID not found");
    }

    const response =
      await this.forwarderTruckerManagementService.getAllTruckersForForwarder(
        userId,
        forwarderId,
        {},
        page,
        ForwarderTruckerManagementLogic.TRUCKERS_PER_PAGE,
        { data: {} },
      );
    return response.data;
  }

  async approveTrucker(truckerId: string, approved: boolean): Promise<void> {
    const userId: string = this.authStore.authUser?.userId ?? "";
    const forwarderId: number = this.forwarderStore.getForwarder()?.id ?? 0;

    if (!userId || !forwarderId) {
      throw new Error("User ID or Forwarder ID not found");
    }

    await this.forwarderTruckerManagementService.approveTrucker(
      userId,
      forwarderId,
      truckerId,
      { approved },
    );
  }

  async updateTrucker(
    truckerId: string,
    updateTrucker: UpdateTruckerForForwarderTO,
  ): Promise<TruckerForForwarderTO> {
    const userId: string = this.authStore.authUser?.userId ?? "";
    const forwarderId: number = this.forwarderStore.getForwarder()?.id ?? 0;

    if (!userId || !forwarderId) {
      throw new Error("User ID or Forwarder ID not found");
    }

    return (
      await this.forwarderTruckerManagementService.updateTrucker(
        userId,
        forwarderId,
        truckerId,
        updateTrucker,
      )
    ).data;
  }

  async deleteTrucker(truckerId: string): Promise<void> {
    const userId: string = this.authStore.authUser?.userId ?? "";
    const forwarderId: number = this.forwarderStore.getForwarder()?.id ?? 0;

    if (!userId || !forwarderId) {
      throw new Error("User ID or Forwarder ID not found");
    }

    await this.forwarderTruckerManagementService.deleteTrucker(
      userId,
      forwarderId,
      truckerId,
    );
  }

  parseIsoIntoDate(date: string, locale: string): string {
    return this.dateService.parseReadableDateFormat(date, locale);
  }

  parseIsoIntoDateTime(date: string, locale: string): string {
    return this.dateService.parseReadableDateTimeFormat(date, locale);
  }
}

let viewModel: ForwarderTruckerManagementLogic | null = null;

export const getViewModel = (): ForwarderTruckerManagementLogic => {
  if (!viewModel) {
    viewModel = new ForwarderTruckerManagementLogic(
      useAuthStore(),
      useForwarderStore(),
      dateService,
      forwarderTruckersClient,
    );
  }

  return viewModel;
};
