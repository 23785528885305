<template>
  <v-date-input
    clearable
    variant="outlined"
    hide-actions
    :prepend-icon="''"
    :append-inner-icon="'mdi-menu-down'"
    locale="en"
    density="compact"
    @update:modelValue="handleUpdateValue"
    @click:clear="handleClearClick"
  >
  </v-date-input>
</template>

<script setup lang="ts">
const emit = defineEmits(["update:modelValue"]);

function handleUpdateValue(value: string | null) {
  emit("update:modelValue", value);
}

function handleClearClick() {
  handleUpdateValue(null);
}
</script>
