import {
  LicenseManagementTableActions,
  LicenseManagementTableActionsEmitEvent,
  LicenseStatus,
} from "@/models/license-management";
import { VirtualTableStatusChipColor } from "@/models/virtual-table";

export function getChipColorByStatus(
  status: LicenseStatus,
): VirtualTableStatusChipColor {
  const statusColorMap: Record<LicenseStatus, VirtualTableStatusChipColor> = {
    EXPIRED: "red",
    ASSIGNED: "blue",
    UNASSIGNED_SETUP_FEE_REQUIRED: "neutral",
    UNASSIGNED: "yellow",
    DISABLED: "neutral",
  };

  return statusColorMap[status] ?? "neutral";
}

export function getLicenseChipStatusValue(value: LicenseStatus) {
  const statusValueMap: Record<LicenseStatus, string> = {
    EXPIRED: "expired",
    ASSIGNED: "assigned",
    UNASSIGNED_SETUP_FEE_REQUIRED: "setup_fee",
    UNASSIGNED: "unassigned",
    DISABLED: "disabled",
  };

  return statusValueMap[value] ?? value.toLowerCase();
}

export function transformLicenseActionToEvent(
  action: LicenseManagementTableActions,
): LicenseManagementTableActionsEmitEvent {
  const parts = action.split("_");
  const transformedParts = parts.map(part => {
    return part.charAt(0).toUpperCase() + part.slice(1).toLowerCase();
  });
  return `on${transformedParts.join("")}` as LicenseManagementTableActionsEmitEvent;
}
