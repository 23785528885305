<template>
  <v-chip class="VirtualTableStatusChip" :color="colorMap.chip">
    <slot>
      <TheText
        class="VirtualTableStatusChip__text"
        :text="value"
        :color="colorMap.text"
      />
    </slot>
  </v-chip>
</template>

<script setup lang="ts">
import { VirtualTableStatusChipColor } from "@/models/virtual-table";
import TheText from "@/components/TheText.vue";
import { computed } from "vue";

interface Props {
  value?: string;
  color?: VirtualTableStatusChipColor;
}

interface ChipColor {
  chip: string;
  text: string;
}
type ChipColorMap = Record<VirtualTableStatusChipColor, ChipColor>;

const props = defineProps<Props>();

const colorMap = computed<ChipColor>(() => {
  const chipColorMap: ChipColorMap = {
    red: {
      chip: "red-50",
      text: "red-900",
    },
    yellow: {
      chip: "yellow-50",
      text: "yellow-900",
    },
    green: {
      chip: "green-50",
      text: "green-900",
    },
    blue: {
      chip: "blue-50",
      text: "blue-900",
    },
    neutral: {
      chip: "neutral-200",
      text: "neutral-900",
    },
  };

  return props.color ? chipColorMap[props.color] : chipColorMap["neutral"];
});
</script>

<style lang="scss">
.VirtualTableStatusChip {
  &__text {
    z-index: 2;
  }

  .v-chip__underlay {
    opacity: 1;
  }
}
</style>
