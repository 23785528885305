import {
  isLicense,
  isLicenseOrderId,
} from "@/utils/license-management/license-utils";

export const ruleRequired =
  (message = "Required") =>
  (value: string) => {
    if (value) return true;
    return message;
  };

export const ruleLicenseFormat =
  (message = "Invalid format") =>
  (value: string) => {
    return isLicense(value) || message;
  };

export const ruleOrderIdFormat =
  (message = "Invalid format") =>
  (value: string) => {
    return isLicenseOrderId(value) || message;
  };

export const ruleLicenseOrOrderId =
  (message = "Invalid format") =>
  (value: string) => {
    const licenseFormat = ruleLicenseFormat();
    const orderIdFormat = ruleOrderIdFormat();
    return (
      licenseFormat(value) === true || orderIdFormat(value) === true || message
    );
  };
