<template>
  <v-form v-model="valid" class="h-100 w-100">
    <v-row :justify="stateIndicatorForm ? 'center' : 'start'">
      <v-col sm="12" :md="stateIndicatorForm ? 12 : 4">
        <TheFileInput
          accept="text/csv"
          :outlined="true"
          :label="$t('selectFile')"
          :rules="fileValidationRules"
          :class="{ 'mx-8': stateIndicatorForm }"
          @change-file="setFile"
        />
      </v-col>

      <v-col
        cols="12"
        :md="stateIndicatorForm ? 4 : 2"
        :class="{ 'pt-0': stateIndicatorForm }"
      >
        <TheButton
          id="submit-button"
          color="primary"
          :elevation="0"
          :is-disabled="isResubmitBatchButtonDisabled || !valid"
          :loading="loading"
          :title="$t('request')"
          @click="submitCsv"
        />
      </v-col>

      <v-col
        v-if="stateIndicatorForm"
        cols="12"
        :md="stateIndicatorForm ? 4 : 2"
        :class="{ 'pt-0': stateIndicatorForm }"
      >
        <TheButton
          color="green"
          :prepend-icon="'mdi-download'"
          :outlined="true"
          :elevation="0"
          :is-disabled="!fetchCompleted"
          :loading="loading"
          :title="$t('download')"
          @click="$emit('download-csv')"
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script setup lang="ts">
import { ref } from "vue";
import TheButton from "@/components/TheButton.vue";
import TheFileInput from "@/components/TheFileInput.vue";
import { useI18n } from "vue-i18n";

const props = defineProps({
  terminalId: {
    type: Number,
    required: true,
  },
  isResubmitBatchButtonDisabled: {
    type: Boolean,
    required: false,
  },
  stateIndicatorForm: {
    type: Boolean,
    required: false,
    default: false,
  },
  fetchCompleted: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emit = defineEmits(["submit-csv", "download-csv"]);
const { t } = useI18n();

const valid = ref(false);
const currentFile = ref<File>();
const loading = ref(false);

const setFile = (payload: { label: string; value: File }) => {
  currentFile.value = payload.value;
};

const fileValidationRules = [
  (v: File[]) => !!v || t("fieldRequired"),
  (v: File[]) => (v[0] && v[0].size < 1000000) || t("fileTooBig"),
];

const submitCsv = (): void => {
  if (valid.value) {
    loading.value = true;
    emit("submit-csv", {
      file: currentFile.value,
      terminalId: props.terminalId,
    });
    loading.value = false;
  }
};
</script>

<style scoped lang="scss"></style>
