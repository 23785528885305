<template>
  <v-app class="overflow-y-auto" id="default-app">
    <TheNavbar :key="currentRoute.fullPath" />
    <v-main class="main">
      <router-view />
      <GlobalSnackbar />
    </v-main>
  </v-app>
</template>

<script setup lang="ts">
import { useRoute } from "vue-router";
import TheNavbar from "@/layouts/navigation-bar/TheNavbar.vue";
import GlobalSnackbar from "@/components/GlobalSnackbar.vue";

const currentRoute = useRoute();
</script>

<style>
/*TODO: import this in index.html*/
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@1,300&display=swap");

#default-app {
  height: 100%;
  width: 100%;
}

.main {
  background-color: #f7f7f7;
}
</style>
