<template>
  <span :class="[vTextClass, vFontSize]">
    <slot>
      {{ text }}
    </slot>
  </span>
</template>

<script setup lang="ts">
import { computed } from "vue";

interface Props {
  color?: string;
  text?: string;
  size?: "14" | "16";
}

const props = defineProps<Props>();

// Add support for hex colors if needed;
// This only works for colors specified in Vuetify.ts
const vTextClass = computed(() => {
  if (!props.color) return "";
  return `text-${props.color}`;
});

const vFontSize = computed(() => {
  return `TheText-${props.size}`;
});
</script>

<style scoped>
.TheText-16 {
  font-size: 16px;
}

.TheText-14 {
  font-size: 14px;
}
</style>
