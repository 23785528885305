<template>
  <div>
    <v-card-text>
      <v-row justify="center">
        <v-col cols="6">
          <GetStatusBatchRequestForm
            :state-indicator-form="true"
            :is-resubmit-batch-button-disabled="isResubmitBatchButtonDisabled"
            :terminal-id="terminal.id"
            :fetch-completed="fetchCompleted"
            @submit-csv="submitCSV"
            @download-csv="$emit('download-csv')"
          />
        </v-col>

        <div class="mb-4">
          <v-divider vertical class="h-100" />
        </div>

        <v-col cols="6" class="text-h6 px-10">
          <div
            class="d-flex align-center justify-center bg-primary rounded-lg status mb-2"
          >
            <span class="w-75 text-right ml-6">
              {{ $t("containersBeingProcessed") }}:
            </span>
            <span
              class="w-50 text-left text-white font-weight-bold text-h6 ml-2"
            >
              {{ progressState.currentIndex }} / {{ lengthOfAllRequests }}
            </span>
          </div>
          <div
            class="d-flex align-center justify-center bg-primary rounded-lg status mb-2"
          >
            <span class="w-75 text-right ml-6">
              {{ $t("foundContainers") }}:
            </span>
            <span
              class="w-50 text-left text-green-accent-3 font-weight-bold text-h6 ml-2"
            >
              {{ progressState.successfulItems }}
            </span>
          </div>
          <div
            class="d-flex align-center justify-center bg-primary rounded-lg status"
          >
            <span class="w-75 text-right ml-6">
              {{ $t("notFoundContainers") }}:
            </span>
            <span class="w-50 text-left text-red font-weight-bold text-h6 ml-2">
              {{ progressState.failedItems }}
            </span>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script setup lang="ts">
import QueueProgressIndicator from "@/views/forwarder-operator/get-status-batch/models/queue-progress-indicator";
import GetStatusBatchRequestForm from "@/views/forwarder-operator/get-status/GetStatusBatchRequestForm.vue";
import { Terminal } from "@/services/client/generated";
import { PropType } from "vue";

defineProps({
  progressState: {
    type: Object as PropType<QueueProgressIndicator>,
    default: () => ({}),
  },
  lengthOfAllRequests: {
    type: Number,
    required: true,
  },
  terminal: {
    type: Object as PropType<Terminal>,
    default: () => ({}),
  },
  isResubmitBatchButtonDisabled: {
    type: Boolean,
    required: true,
  },
  fetchCompleted: {
    type: Boolean,
    required: true,
    default: false,
  },
});

const emit = defineEmits(["submit-csv", "download-csv"]);

const submitCSV = (payload: { file: File }) => {
  emit("submit-csv", payload);
};
</script>

<style scoped lang="scss">
.status {
  height: 2.5em;
}
</style>
