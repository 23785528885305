<template>
  <v-btn
    fluid
    block
    :rounded="rounded"
    :variant="outlined ? 'outlined' : 'flat'"
    :disabled="isDisabled"
    :loading="loading"
    :color="color"
    :elevation="elevation"
    :size="size ? size : 'x-large'"
    @click.prevent="clicked"
  >
    <slot>
      {{ title }}
    </slot>
  </v-btn>
</template>

<script setup lang="ts">
import { PropType } from "vue";
import { ElementSize } from "@/internal-models/element-size";

defineProps({
  title: {
    type: String,
    required: false,
    default: undefined,
  },
  color: {
    type: String,
    required: false,
    default: "primary",
  },
  size: {
    type: String as PropType<ElementSize>,
    required: false,
    default: null,
  },
  loading: {
    type: Boolean,
    required: false,
  },
  elevation: {
    type: Number,
    required: false,
    default: 6,
  },
  rounded: {
    type: String as PropType<"xs" | "sm" | "lg" | "xl" | number>,
    required: false,
    default: "md",
  },
  outlined: {
    type: Boolean,
    required: false,
    default: false,
  },
  width: {
    type: Number,
    required: false,
    default: null,
  },
  isDisabled: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emit = defineEmits(["clicked-button"]);

const clicked = () => {
  emit("clicked-button");
};
</script>
