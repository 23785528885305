<template>
  <div>
    <CardHeaderSection
      :title="title"
      :subtitle="subtitle"
      :show-settings="settings"
      :button="button"
      :button-label="buttonLabel"
      :header-section-name="headerSectionName"
      :main-title="mainTitle"
      :disable-button="disableButton"
      @buttonClick="onButtonClick"
    >
      <template v-for="(_, slotName) in $slots" #[slotName]="slotProps">
        <slot :name="slotName" v-bind="slotProps" />
      </template>
    </CardHeaderSection>

    <SearchFilterSection
      v-if="searchFilterVersion === 1"
      :searchable="searchable"
      :filterable="filterable"
      :search-type="searchType"
      :search-text="searchText"
      @search="updateSearch"
      @search-date="updateDate"
    >
      <template v-for="(_, slotName) in $slots" #[slotName]="slotProps">
        <slot :name="slotName" v-bind="slotProps" />
      </template>
    </SearchFilterSection>

    <SearchFilterSectionV2
      v-if="searchFilterVersion === 2"
      :searchable="searchable"
      :filterable="filterable"
      :search-text="searchText"
      @on-search="handleSearch"
      @on-filter="handleFilter"
    >
      <template #custom-btn>
        <slot name="filter-custom-btn" />
      </template>
    </SearchFilterSectionV2>

    <DataTableSection
      :items="items"
      :headers="headers"
      :item-key="itemKey"
      :no-data-text="noDataText"
      :row-props="rowProps"
      :show-select="showSelect"
      :search="searchValue"
      :custom-filter="customFilter"
      :pages-loaded="pagesLoaded"
      :item-value="itemValue"
      :return-object="returnObject"
      :select-strategy="selectStrategy"
      :loading="loading"
      :infinite-scroll-mode="infiniteScrollMode"
      :scrollable="scrollable"
      :min-width="minWidth"
      @update:model-value="onModelValueUpdate"
      @load-more="e => onScroll(e)"
    >
      <template v-for="(_, slotName) in $slots" #[slotName]="slotProps">
        <slot :name="slotName" v-bind="slotProps" />
      </template>
    </DataTableSection>
  </div>
</template>

<script setup lang="ts" generic="T">
import { computed, PropType, ref } from "vue";
import CardHeaderSection from "./CardHeaderSection.vue";
import SearchFilterSection from "./SearchFilterSection.vue";
import DataTableSection from "./DataTableSection.vue";
import SearchFilterSectionV2 from "@/components/virtual-table/SearchFilterSectionV2.vue";

const props = defineProps({
  pagesLoaded: {
    type: Number,
    default: 0,
    required: true,
  },
  title: {
    type: String,
    default: "",
  },
  subtitle: {
    type: String,
    default: "",
  },
  settings: {
    type: Boolean,
    default: false,
  },
  items: {
    type: Array as PropType<readonly T[]>,
    required: true,
  },
  headers: {
    type: Array as PropType<
      readonly {
        key?: string;
        title: string;
        value: string;
        align?: "start" | "end" | "center";
      }[]
    >,
    required: true,
  },
  selectStrategy: {
    type: String as PropType<"single" | "page" | "all">,
    default: "page",
    required: false,
  },
  infiniteScrollMode: {
    type: String as PropType<"manual" | "intersect">,
    default: "intersect",
    required: false,
  },
  itemKey: {
    type: String,
    default: "id",
  },
  buttonLabel: {
    type: String,
    default: "Add Item",
  },
  noDataText: {
    type: String,
    default: "No data available",
  },
  button: {
    type: Boolean,
    default: false,
  },
  disableButton: {
    type: Boolean,
    default: false,
  },
  rowProps: {
    type: Function as PropType<
      (row: {
        index: number;
        internalItem: object;
        item: T;
      }) => Record<string, unknown>
    >,
    required: false,
    default: () => ({}),
  },
  loading: {
    type: Boolean,
    default: false,
  },
  showSelect: {
    type: Boolean,
    default: false,
  },
  returnObject: {
    type: Boolean,
    default: false,
  },
  itemValue: {
    type: String,
    default: "",
    required: false,
  },
  searchable: {
    type: Boolean,
    default: false,
  },
  filterable: {
    type: Boolean,
    defualt: false,
  },
  searchText: {
    type: String,
    default: "search",
  },
  searchPlaceholder: {
    type: String,
  },
  searchType: {
    type: String as PropType<"text" | "date">,
    default: "text",
  },
  headerSectionName: {
    type: String,
    required: false,
    default: "",
  },
  mainTitle: {
    type: Boolean,
    required: false,
    default: true,
  },
  customFilter: {
    type: Function as PropType<
      (
        value: string,
        query: string,
        item?: { value: unknown; raw: T },
      ) => boolean
    >,
    default: () => true,
  },
  searchFilterVersion: {
    type: Number,
    required: false,
    default: 1,
  },
  scrollable: {
    type: Boolean,
    default: false,
  },
  minWidth: {
    type: Number,
  },
});

const emit = defineEmits([
  "buttonClick",
  "update:modelValue",
  "load-more",
  "onSearch",
  "onFilter",
]);

const search = ref("");
const searchDate = ref("");

const searchValue = computed(() => {
  return search.value || searchDate.value || "";
});

const onButtonClick = () => {
  emit("buttonClick");
};

const updateSearch = (value: string) => {
  search.value = value;
};

const updateDate = (value: string) => {
  searchDate.value = value;
};

const onModelValueUpdate = (event: unknown) => {
  emit("update:modelValue", event);
};

const onScroll = (
  done: (status: "loading" | "error" | "empty" | "ok") => void,
) => {
  if (!props.items || props.items.length === 0) {
    done("empty");
  }
  emit("load-more", done);
};

const handleSearch = (value: string) => {
  emit("onSearch", value);
};

const handleFilter = () => {
  emit("onFilter");
};
</script>
