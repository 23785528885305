import { defineStore } from "pinia";
import { ref, computed } from "vue";
import {
  ForwarderOperatorConfig,
  GetStatusFormPreferences,
} from "./interfaces/user-role-config";
import { forwarderTruckersClient } from "@/services/client/configs/services";

export const useForwarderOperatorStore = defineStore(
  "forwarderOperatorStore",
  () => {
    const forwarderOperatorConfigState = ref<ForwarderOperatorConfig>(
      {} as ForwarderOperatorConfig,
    );

    const forwarderOperatorConfig = computed(
      () => forwarderOperatorConfigState.value,
    );

    const forwarderOperatorGetStatusPreferences = computed(
      (): GetStatusFormPreferences | undefined => {
        return forwarderOperatorConfigState.value.getStatusFormPreferences;
      },
    );

    const setGetStatusPreferences = (preferences: GetStatusFormPreferences) => {
      forwarderOperatorConfigState.value.getStatusFormPreferences = preferences;
    };

    const getGetStatusPreferences = ():
      | GetStatusFormPreferences
      | undefined => {
      return forwarderOperatorConfigState.value.getStatusFormPreferences;
    };

    const rememberGetStatusPreferences = (
      references: GetStatusFormPreferences,
    ) => {
      setGetStatusPreferences(references);
    };

    const purchaseLicenses = ({
      userId,
      forwarderId,
      successUrl,
      companyEmail,
      quantity,
    }: {
      userId: string;
      forwarderId: number;
      successUrl: string;
      companyEmail: string;
      quantity: number;
    }) => {
      return forwarderTruckersClient.stripeCheckout(userId, forwarderId, {
        successUrl,
        licenseCompanyEmail: companyEmail,
        quantity,
      });
    };

    return {
      forwarderOperatorConfigState,
      forwarderOperatorConfig,
      forwarderOperatorGetStatusPreferences,
      setGetStatusPreferences,
      getGetStatusPreferences,
      rememberGetStatusPreferences,
      purchaseLicenses,
    };
  },
  {
    persist: true,
  },
);
