import {
  ClaimedLicense,
  ClaimLicenseError,
  License,
  LicenseManagementGenericError,
} from "@/models/license-management";
import { AxiosError, isAxiosError } from "axios";

export function isLicense(license: string) {
  const licensePattern = /^[A-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{4}$/;
  return licensePattern.test(license);
}

export function isLicenseOrderId(id: string) {
  const orderIdPattern = /^sub_[A-Za-z0-9]{24}$/;
  return orderIdPattern.test(id);
}

export function isClaimedLicenseAssigned(
  claimedLicense: ClaimedLicense | null | undefined,
) {
  return Boolean(
    claimedLicense && claimedLicense.status === "ALREADY_ASSIGNED",
  );
}

export function isClaimedLicenseAvailable(
  claimedLicense: ClaimedLicense | null | undefined,
) {
  return Boolean(claimedLicense && claimedLicense.status === "CLAIMED");
}

export function getClaimError(
  err: AxiosError<ClaimLicenseError>,
): ClaimLicenseError | undefined {
  if (!isAxiosError(err)) return;
  const claimError = err.response?.data;
  if (!claimError) return;

  if (claimError.code) {
    return claimError;
  }
}

export function updateLicenses(licenses?: License[] | null, license?: License) {
  if (!licenses || !license) return licenses;
  return licenses.map(lic => {
    if (lic.id === license.id) return license;
    return lic;
  });
}

export function getLicenseError<T extends LicenseManagementGenericError>(
  err: AxiosError<T>,
): T | undefined {
  if (!isAxiosError(err)) return;
  const actionError = err.response?.data;
  if (!actionError) return;

  if (actionError.code) {
    return actionError;
  }
}
