export function useLocalRoute() {
  function getOrigin() {
    return window.location.origin;
  }

  function goToExternalRoute(value: string) {
    window.location.replace(value);
  }

  return {
    getOrigin,
    goToExternalRoute,
  };
}
