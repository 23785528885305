<template>
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.89258 15.9697L17.8926 6.96973L16.4863 5.5166L8.89258 13.1104L5.33008 9.54785L3.92383 10.9541L8.89258 15.9697ZM3.83008 3.92285C5.79883 1.9541 8.1582 0.969727 10.9082 0.969727C13.6582 0.969727 16.002 1.9541 17.9395 3.92285C19.9082 5.86035 20.8926 8.2041 20.8926 10.9541C20.8926 13.7041 19.9082 16.0635 17.9395 18.0322C16.002 19.9697 13.6582 20.9385 10.9082 20.9385C8.1582 20.9385 5.79883 19.9697 3.83008 18.0322C1.89258 16.0635 0.923829 13.7041 0.923829 10.9541C0.923829 8.2041 1.89258 5.86035 3.83008 3.92285Z"
      fill="#1C6E3A"
    />
  </svg>
</template>
