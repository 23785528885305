<template>
  <v-container
    @click="handleRouting"
    :class="`rounded-lg ${backgroundImage}Tile ${isVisible} pa-2`"
  >
    <span class="d-flex position-absolute">
      <v-icon
        v-if="item.icon"
        class="pa-1"
        color="white"
        :icon="item.icon"
        :data-testid="`test-icon-${item.icon}`"
      />
    </span>

    <div class="pt-14 py-16 pb-16">
      <span id="title" class="text-h5 font-weight-bold text-white">
        {{ uppercaseTitle }}
      </span>
    </div>
  </v-container>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useRouter } from "vue-router";
import PageCardItem from "@/internal-models/page-card-item";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  item: PageCardItem;
  usable: boolean;
}>();

const { t } = useI18n();
const router = useRouter();

const handleRouting = (): void => {
  if (!props.usable) return;
  router.push(props.item?.page);
};

const isVisible = computed((): string => {
  return props.usable ? "cursor-pointer" : "opacity-30";
});

const uppercaseTitle = computed((): string => {
  return t(props.item.title).toUpperCase();
});

const backgroundImage = computed((): string => {
  switch (props.item?.background) {
    case "graph":
      return "conrooUser";
    case "operator-chair":
      return "trainOperator";
    case "trucks":
      return "forwarders";
    case "licenseManagement":
      return "licenseManagement";
    default:
      return "terminalOperator";
  }
});
</script>

<style lang="scss" scoped>
.terminalOperatorTile {
  background-size: cover;
  background-image: url("@/assets/thumbnails/terminals.png");
}

.conrooUserTile {
  background-size: cover;
  background-image: url("@/assets/thumbnails/graph.png");
}

.trainOperatorTile {
  background-size: cover;
  background-image: url("@/assets/thumbnails/operator-chair.png");
}

.forwardersTile {
  background-size: cover;
  background-image: url("@/assets/thumbnails/trucks.png");
}

.licenseManagementTile {
  background-image: url("@/assets/thumbnails/shield.png");
  background-position: center;
  background-size: cover;
}
</style>
