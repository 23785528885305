import { ref } from "vue";

export function useDebounce(timeout: number) {
  const timeoutSession = ref<NodeJS.Timeout | null>(null);

  function debounce(cb: () => void) {
    stop();
    timeoutSession.value = setTimeout(() => {
      cb();
    }, timeout);
  }

  function stop() {
    if (timeoutSession.value) clearTimeout(timeoutSession.value);
  }

  return { debounce, stop };
}
